import React, {Component} from 'react'
import Headerfour from '../../layouts/Headerfour';
import Footer from '../../layouts/Footer';
import {Link} from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import {Equipment} from "../../sections/room/Equipment";
import {Facilities} from "../../sections/room/Facilities";
import {Location} from "../../sections/room/Location";
import {Map} from "../../sections/room/Map";
import "react-image-gallery/styles/css/image-gallery.css";

import Image1 from '../../../assets/img/room/1/01.jpg';
import Image2 from '../../../assets/img/room/1/02.jpg';
import Image3 from '../../../assets/img/room/1/03.jpg';
import Image4 from '../../../assets/img/room/1/04.jpg';
import Image5 from '../../../assets/img/room/1/05.jpg';
import Image6 from '../../../assets/img/room/1/06.jpg';

const images = [
    {
        original: Image1,
    },
    {
        original: Image2,
    },
    {
        original: Image3,
    },
    {
        original: Image4,
    },
    {
        original: Image5,
    },
    {
        original: Image6,
    },
];

class Roomdetails extends Component {
    render() {
        return (
            <div>
                <Headerfour/>
                <section className="breadcrumb-area" style={{backgroundColor: '#4b9900'}}>
                    <div className="container">
                        <div className="breadcrumb-text">
                            <h2 className="page-title">Pokój nr 1</h2>
                            <ul className="breadcrumb-nav">
                                <li><Link to="/">Strona główna</Link></li>
                                <li className="active">Pokój nr 1</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="room-details pt-120 pb-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="deatils-box">
                                    <div className="thumb">
                                        <ImageGallery items={images} showThumbnails={false} lazyLoad={true} autoPlay={true} />
                                    </div>
                                    <Equipment/>
                                    <Facilities/>
                                    <Location/>
                                    <Map/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>

        );
    }
}

export default Roomdetails;
