import React, {Component} from 'react'
import Headerfour from '../layouts/Headerfour';
import Footer from '../layouts/Footer';
import {Link} from 'react-router-dom';

class Contact extends Component {
    render() {
        return (
            <div>
                <Headerfour/>
                {/*====== BREADCRUMB PART START ======*/}
                <section className="breadcrumb-area" style={{backgroundColor: '#4b9900'}}>
                    <div className="container">
                        <div className="breadcrumb-text">
                            <h2 className="page-title">Kontakt</h2>
                            <ul className="breadcrumb-nav">
                                <li><Link to="/">Strona główna</Link></li>
                                <li className="active">Kontakt</li>
                            </ul>
                        </div>
                    </div>
                </section>
                {/*====== BREADCRUMB PART END ======*/}
                {/*====== CONTACT PART START ======*/}
                <section className="contact-part pt-115 pb-115">
                    <div className="container">
                        {/* Contact Info */}
                        <div className="contact-info">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-sm-6 col-10">
                                    <div className="info-box">
                                        <div className="icon">
                                            <i className="flaticon-home"/>
                                        </div>
                                        <div className="desc">
                                            <h4>Adres</h4>
                                            <p>
                                                Pokoje gościnne „ZIELONA WILLA"<br/>
                                                Ul. Zielona 13<br/>
                                                78-132 Grzybowo<br/><br/>
                                                Lokalizacja GPS: 54,161555 15,501881
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-10">
                                    <div className="info-box">
                                        <div className="icon">
                                            <i className="flaticon-phone"/>
                                        </div>
                                        <div className="desc">
                                            <h4>Numer telefonu</h4>
                                            <p>
                                                <Link to={{pathname: "tel:+48504922252"}} target="_blank">
                                                    +48 504 922 252
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-10">
                                    <div className="info-box">
                                        <div className="icon">
                                            <i className="flaticon-message"/>
                                        </div>
                                        <div className="desc">
                                            <h4>Adres email</h4>
                                            <p>
                                                <Link to={{pathname: "mailto:kontakt@zielona.kolobrzeg.pl"}}
                                                      target="_blank">
                                                    info@zielona.kolobrzeg.pl
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="contact-maps mb-30">
                            <iframe
                                src="https://maps.google.com/maps?q=zielona 13 grzybowo&t=&z=14&ie=UTF8&iwloc=&output=embed"
                                title="title"/>
                        </div>
                        {/* Contact Form */}
                        {/*<div className="contact-form">*/}
                        {/*  <form action="#">*/}
                        {/*    <div className="row">*/}
                        {/*      <div className="col-md-6">*/}
                        {/*        <div className="input-group mb-30">*/}
                        {/*          <span className="icon"><i className="far fa-user" /></span>*/}
                        {/*          <input type="text" placeholder="Your full name" />*/}
                        {/*        </div>*/}
                        {/*      </div>*/}
                        {/*      <div className="col-md-6">*/}
                        {/*        <div className="input-group mb-30">*/}
                        {/*          <span className="icon"><i className="far fa-envelope" /></span>*/}
                        {/*          <input type="email" placeholder="Enter email address" />*/}
                        {/*        </div>*/}
                        {/*      </div>*/}
                        {/*      <div className="col-md-6">*/}
                        {/*        <div className="input-group mb-30">*/}
                        {/*          <span className="icon"><i className="far fa-phone" /></span>*/}
                        {/*          <input type="text" placeholder="Add phone number" />*/}
                        {/*        </div>*/}
                        {/*      </div>*/}
                        {/*      <div className="col-md-6">*/}
                        {/*        <div className="input-group mb-30">*/}
                        {/*          <span className="icon"><i className="far fa-book" /></span>*/}
                        {/*          <input type="text" placeholder="Select Subject" />*/}
                        {/*        </div>*/}
                        {/*      </div>*/}
                        {/*      <div className="col-12">*/}
                        {/*        <div className="input-group textarea mb-30">*/}
                        {/*          <span className="icon"><i className="far fa-pen" /></span>*/}
                        {/*          <textarea type="text" placeholder="Enter messages" defaultValue={""} />*/}
                        {/*        </div>*/}
                        {/*      </div>*/}
                        {/*      <div className="col-12 text-center">*/}
                        {/*        <button type="submit" className="main-btn btn-filled">Get Free Quote</button>*/}
                        {/*      </div>*/}
                        {/*    </div>*/}
                        {/*  </form>*/}
                        {/*</div>*/}
                    </div>
                </section>
                <Footer/>
            </div>

        );
    }
}

export default Contact;
