import React from "react";

const Map = () => {
    return (
        <div className="room-map mb-20" id="roomMap">
            <iframe src="https://maps.google.com/maps?q=zielona 13 grzybowo&t=&z=14&ie=UTF8&iwloc=&output=embed" title="title" />
        </div>
    );
}

export {Map};