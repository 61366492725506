import React from "react";

const EquipmentWithoutBalcony = () => {
    return (
        <div className="room-fearures clearfix mt-60 mb-60">
            <h3 className="subtitle">Wyposażenie pokoju</h3>
            <ul className="room-fearures-list">
                <li><i className="fal fa-bed"/>Podstawowe wyposażenie (łóżka, szafa, stolik, krzesła, kołdry, poduszki)
                </li>
                <li><i className="fal fa-bath"/>Łazienka z prysznicem</li>
                <li><i className="fal fa-utensils"/>Aneks kuchenny (lodówka, mikrofala, czajnik, kubki, talerze,
                    sztućce)
                </li>
                <li><i className="fal fa-tv"/>Telewizor</li>
                <li><i className="fal fa-wifi"/>Internet bezprzewodowy Wi-Fi</li>
            </ul>
        </div>
    );
}

export {EquipmentWithoutBalcony};