import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import logo from '../../assets/img/logo.png';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redText: false
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
            this.setState({
                isTop: window.scrollY > 300
            });
        }, false);
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    render() {
        const className = this.state.isTop ? 'active' : '';
        return (
            <div>
                <Link to="#" className={`back-to-top ${className}`} id="backToTop" onClick={() => this.scrollToTop()}>
                    <i className="fal fa-angle-double-up"/>
                </Link>
                <footer>
                    <div className="footer-subscibe-area pt-100 pb-120">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="subscribe-text text-center">
                                        <div className="footer-logo mb-45">
                                            <img src={logo} alt=""/>
                                        </div>
                                        <p>
                                            Dołącz do newslettera i bądź na bieżąco z naszą ofertą noclegową.
                                        </p>
                                        <form action="#" className="subscribe-form mt-50">
                                            <input type="email" placeholder="Wprowadź adres email"/>
                                            <button type="submit">Zapisz się</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-area pt-20 pb-20">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-5 order-2 order-md-1">
                                    <p className="copyright-text">zielona.kolobrzeg.pl</p>
                                </div>
                                <div className="col-md-7 order-1 order-md-2">
                                    <div className="social-links">
                                        <Link to={{pathname: "https://fb.com/zielonawilla"}} target="_blank">
                                            <i className="fab fa-facebook-f"/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;
