import React from "react";

const Location = () => {
    return (
        <div className="bg-white clearfix mt-60 mb-60 pl-40 pt-40 pb-40 pr-40">
            <h3 className="subtitle">Lokalizacja</h3>
            Pokoje gościnne „Zielona Willa” to całoroczny obiekt idealny na zarówno na wczasy rodzinne, weekendowe
            wczasy we dwoje praz na pobyty dla grup zorganizowanych. „Zielona Willa” usytuowana jest w spokojnej
            dzielnicy 600 m do najszerszej i najczystrzej plaży w grzybowie. W odległości 300 m znajduje się samo
            centrum Grzybowa smażalnie, lodziarnie, sklepy spożywcze, jadłodajnia pizzerie oraz wypożyczalnia rowerów są
            w zasięgu ręki.
        </div>
    );
}

export {Location};