import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';

import Room1Image from '../../../assets/img/room/1/01.jpg';
import Room2Image from '../../../assets/img/room/2/01.jpg';
import RoomApartmentImage from '../../../assets/img/room/apartament/01.jpg';
import Room5Image from '../../../assets/img/room/5/01.jpg';
import Room6Image from '../../../assets/img/room/6/01.jpg';
import Room7Image from '../../../assets/img/room/7/01.jpg';
import Room8Image from '../../../assets/img/room/8/01.jpg';
import RoomGroundImage from '../../../assets/img/room/ground/01.jpg';

class Roomtype extends Component {
  render() {
    return (
        <section className="room-type-section pt-115 pb-115">
          <Tab.Container defaultActiveKey="one">
              <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="section-title text-lg-left text-center">
                  <span className="title-tag">wybierz swój</span>
                  <h2>Pokój</h2>
                </div>
              </div>
              <div className="col-lg-6">
              <Nav variant="pills" className="room-filter nav nav-pills justify-content-center justify-content-lg-end">

                <Nav.Item>
                <Nav.Link eventKey="one">2 osobowe</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                <Nav.Link eventKey="two">3 osobowe</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                <Nav.Link eventKey="three">4 osobowe i więcej</Nav.Link>
                </Nav.Item>

                </Nav>
                </div>
            </div>
            <Tab.Content className="mt-65">
                <Tab.Pane eventKey="one">
                    <div className="room-items">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="row">
                            <div className="col-12">
                            <div className="room-box extra-wide">
                                <div className="room-bg" style={{backgroundImage: "url("+ Room1Image + ")"}} />
                                <div className="room-content">
                                <h3><Link to="/pokoj-1">Pokój nr 1</Link></h3>
                                </div>
                                <Link to="/pokoj-1" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            <div className="room-box">
                                <div className="room-bg" style={{backgroundImage: "url("+ Room6Image + ")"}} />
                                <div className="room-content">
                                <h3><Link to="/pokoj-6">Pokój nr 6</Link></h3>
                                </div>
                                <Link to="/pokoj-6" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            <div className="room-box">
                                <div className="room-bg" style={{backgroundImage: "url("+ Room8Image + ")"}} />
                                <div className="room-content">
                                <h3><Link to="/pokoj-8">Pokój nr 8</Link></h3>
                                </div>
                                <Link to="/pokoj-8" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                        </div>
                        </div>
                        {/*<div className="col-lg-4">*/}
                        {/*<div className="room-box extra-height">*/}
                        {/*    <div className="room-bg" style={{backgroundImage: "url("+ RoomGroundImage + ")"}} />*/}
                        {/*    <div className="room-content">*/}
                        {/*    <h3><Link to="/pokoj-parter">Pokój na parterze</Link></h3>*/}
                        {/*    </div>*/}
                        {/*    <Link to="/pokoj-parter" className="room-link"><i className="fal fa-arrow-right" /></Link>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                    </div>
                    </div>
                </Tab.Pane>

                <Tab.Pane eventKey="two">
                    <div className="room-items">
                    <div className="row">
                        <div className="col-lg-4">
                        <div className="room-box extra-height">
                            <div className="room-bg" style={{backgroundImage: "url("+ Room1Image + ")"}} />
                            <div className="room-content">
                            <h3><Link to="/pokoj-1">Pokój nr 1</Link></h3>
                            </div>
                            <Link to="/pokoj-1" className="room-link"><i className="fal fa-arrow-right" /></Link>
                        </div>
                        </div>
                        <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                            <div className="room-box">
                                <div className="room-bg" style={{backgroundImage: "url("+ Room2Image + ")"}} />
                                <div className="room-content">
                                <h3><Link to="/pokoj-2">Pokój nr 2</Link></h3>
                                </div>
                                <Link to="/pokoj-2" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            <div className="room-box">
                                <div className="room-bg" style={{backgroundImage: "url("+ Room5Image + ")"}} />
                                <div className="room-content">
                                <h3><Link to="/pokoj-5">Pokój nr 5</Link></h3>
                                </div>
                                <Link to="/pokoj-5" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                            <div className="col-12">
                            <div className="room-box extra-wide">
                                <div className="room-bg" style={{backgroundImage: "url("+ Room7Image + ")"}} />
                                <div className="room-content">
                                <h3><Link to="/pokoj-7">Pokój nr 7</Link></h3>
                                </div>
                                <Link to="/pokoj-7" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </Tab.Pane>

                <Tab.Pane eventKey="three">
                    <div className="room-items">
                    <div className="row">
                        <div className="col-lg-4">
                        <div className="room-box extra-height">
                            <div className="room-bg" style={{backgroundImage: "url("+ RoomApartmentImage + ")"}} />
                            <div className="room-content">
                            <h3><Link to="/apartament">Apartament dwupokojowy</Link></h3>
                            </div>
                            <Link to="/apartament" className="room-link"><i className="fal fa-arrow-right" /></Link>
                        </div>
                        </div>
                    </div>
                    </div>
                </Tab.Pane>
            </Tab.Content>
            </div>
            </Tab.Container>
        </section>
    );
  }
}

export default Roomtype;
