import React, {useEffect} from 'react';
import {BrowserRouter, Route, Switch, useLocation} from 'react-router-dom';
import Preloader from './components/layouts/Preloader';
import Home from './components/pages/Home';
import Roomgrid from './components/pages/Roomgrid';
import Roomdetails from './components/pages/Roomdetails';
import Contact from './components/pages/Contact';
import Room1 from "./components/pages/Rooms/Room1";
import Room2 from "./components/pages/Rooms/Room2";
import Room6 from "./components/pages/Rooms/Room6";
import Room5 from "./components/pages/Rooms/Room5";
import Room7 from "./components/pages/Rooms/Room7";
import Room8 from "./components/pages/Rooms/Room8";
import RoomApartment from "./components/pages/Rooms/RoomApartment";
import ReactGA from "react-ga";

const usePageViews = () => {
    let location = useLocation()

    useEffect(
        () => {
            ReactGA.pageview(location.pathname);
        },
        [location.pathname]
    )
}


const App = () => {
    usePageViews();

    return (
        <Switch>
            <Route exact path='/' component={Home}/>
            <Route path='/pokoje' component={Roomgrid}/>
            <Route path='/kontakt' component={Contact}/>
            <Route path='/pokoj-1' component={Room1}/>
            <Route path='/pokoj-2' component={Room2}/>
            <Route path='/pokoj-5' component={Room5}/>
            <Route path='/pokoj-6' component={Room6}/>
            <Route path='/pokoj-7' component={Room7}/>
            <Route path='/pokoj-8' component={Room8}/>
            <Route path='/pokoj-parter' component={Roomdetails}/>
            <Route path='/apartament' component={RoomApartment}/>
        </Switch>
    );
}

export default () => (
    <BrowserRouter>
        {<Preloader/>}
        <App/>
    </BrowserRouter>
)