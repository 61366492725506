import React, { Component } from 'react'
import Headerfour from '../layouts/Headerfour';
import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';

import Room1Image from '../../assets/img/room/1/01.jpg';
import Room2Image from '../../assets/img/room/2/01.jpg';
import RoomApartmentImage from '../../assets/img/room/apartament/01.jpg';
import Room5Image from '../../assets/img/room/5/01.jpg';
import Room6Image from '../../assets/img/room/6/01.jpg';
import Room7Image from '../../assets/img/room/7/01.jpg';
import Room8Image from '../../assets/img/room/8/01.jpg';

const roomgridPosts = [
  {
      photo: Room1Image,
      title: 'Pokój nr 1',
      url: '/pokoj-1',
      price: '$345/Night',
      book: 'Zobacz pokój',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',
  },
  {
      photo: Room2Image,
      title: 'Pokój nr 2',
      url: '/pokoj-2',
      price: '$345/Night',
      book: 'Booking Now',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',
  },
  {
      photo: RoomApartmentImage,
      title: 'Apartament dwupokojowy',
      url: '/apartament',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',
  },
  {
      photo: Room5Image,
      title: 'Pokój nr 5',
      url: '/pokoj-5',
      price: '$345/Night',
      book: 'Booking Now',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',
  },
  {
      photo: Room6Image,
      title: 'Pokój nr 6',
      url: '/pokoj-6',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',
  },
  {
      photo: Room7Image,
      title: 'Pokój nr 7',
      url: '/pokoj-7',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',
  },
  {
      photo: Room8Image,
      title: 'Pokój nr 8',
      url: '/pokoj-8',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',
  },
  // {
  //   photo: parterImg,
  //   title: 'Pokój parter',
  //   url: '/pokoj-parter',
  //   price: '$345/Night',
  //   book: 'Zobacz pokój',
  //   desc: 'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',
  // },
];

class Roomgrid extends Component {
  render() {
    return (
      <div>
        <Headerfour />
        <section className="breadcrumb-area" style={{backgroundColor: '#4b9900'}}>
          <div className="container">
            <div className="breadcrumb-text">
              <h2 className="page-title">Nasze pokoje</h2>
              <ul className="breadcrumb-nav">
                <li><Link to="/">Strona główna</Link></li>
                <li className="active">Pokoje</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="room-section room-grid-style pt-120 pb-120">
          <div className="container">
            <div className="row justify-content-center room-gird-loop">
            {roomgridPosts.map((item, i) => (
              <div key={i} className="col-lg-4 col-md-6 col-sm-6">
                  <Link to={item.url} className="w-100">
                <div className="room-box mb-30 w-100">
                    <div className="room-img-wrap">
                    <div className="room-img" style={{backgroundImage: "url("+ item.photo + ")"}} />
                    </div>
                    <div className="room-desc">
                    <ul className="icons">
                        <li><i className="fal fa-bed" /></li>
                        <li><i className="fal fa-wifi" /></li>
                        <li><i className="fal fa-car" /></li>
                    </ul>
                    <h4 className="title"><Link to={item.url}>{item.title}</Link></h4>
                    {/*<p>{item.desc}</p>*/}
                    <Link to={item.url} className="book-btn">Zobacz pokój</Link>
                    </div>
                </div>
                  </Link>
              </div>
            ))} 
              
            </div>
          </div>
        </section>
        <Footer />
      </div>

    );
  }
}

export default Roomgrid;
