import React, { Component } from 'react';
import ReactGA from 'react-ga';

class Preloader extends Component {
  constructor(props) {
    super(props);
    this.state = {
    	fetchSuccess: false
	}
  }

  componentDidMount() {
      ReactGA.initialize('UA-45191748-14');
      //
      // fetch('assets/img/logo.png')
      // .then(response => {
		// this.setState({
		// 	fetchSuccess: true
		// });
  	// });
  }
  render() {
    return '';
    // const{ fetchSuccess } = this.state;
    // return (
    //   <div className={ (fetchSuccess? "d-none ":"") + "preloader align-items-center justify-content-center "}>
    //     <div className="cssload-container">
    //       <div className="cssload-loading"><i /><i /><i /><i /></div>
    //     </div>
    //   </div>
    // );
  }
}

export default Preloader;
