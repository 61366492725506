import React from "react";

const Facilities = () => {
    return (
        <div className="room-fearures clearfix mt-60 mb-60">
            <h3 className="subtitle">Udogodnienia</h3>
            <ul className="room-fearures-list">
                <li><i className="fal fa-check-circle" />Kuchnia polowa</li>
                <li><i className="fal fa-check-circle" />Żelazko + deska do prasowania</li>
                <li><i className="fal fa-check-circle" />Grill i miejsce do wieczornego wypoczynku</li>
                <li><i className="fal fa-parking" />Miejsce parkingowe</li>
                <li><i className="fal fa-check-circle" />Pralka (w obrębie części wspólnej) - <small>dodatkowo płatna</small></li>
            </ul>
        </div>
    );
}

export {Facilities};