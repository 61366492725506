import React, { Component } from 'react'
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Mainbanner from '../sections/homepage-one/Banner';
import Bookingform from '../sections/homepage-one/Bookingform';
import About from '../sections/homepage-one/About';
import Roomtype from '../sections/homepage-one/Roomtype';
import Cta from '../sections/homepage-one/Cta';
import Roomslider from '../sections/homepage-one/Roomslider';
import Textblock from '../sections/homepage-one/Textblock';
import Testimonial from '../sections/homepage-one/Testimonials';
import Roomgallery from '../sections/homepage-one/Roomgallery';
import News from '../sections/homepage-one/News';

class Home extends Component {
  render() {
    return (
      <div>
        <Header/>
        <Mainbanner />
        {/*<Bookingform />*/}
        {/*<About />*/}
        <Roomtype />
        {/*<Cta/>*/}
        {/*<Roomslider/>*/}
        {/*<Textblock/>*/}
        {/*<Testimonial/>*/}
        {/*<Roomgallery/>*/}
        {/*<News/>*/}
        <Footer/>
      </div>
    );
  }
}

export default Home;
